<template>
  <el-container class="shopList">
    <el-header class="box add bb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>系统配置</el-breadcrumb-item>
        <el-breadcrumb-item>系统配置列表</el-breadcrumb-item>
      </el-breadcrumb>
      <div>
        <el-button class icon="el-icon-plus" size="medium" type="primary"
          @click="Add()">新增系统配置</el-button>
      </div>
    </el-header>
    <el-main class="pt0">
      <el-row class="box search mt20" :gutter="10">
        <el-form :inline="true" :model="page" class="demo-form-inline">
          <el-col :span="4">
            <el-form-item size="medium">
              <el-input clearable placeholder="请输入系统配置名称" v-model="page.config_name" class="input-with-select"
                @change="onSubmit(page.config_name)"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item size="medium">
              <el-select clearable v-model="provinceTemp" placeholder="请选择省级" @change="handleChange($event,2)"
                value-key="region_name" >
                <el-option v-for="item in provinceOptions" :key="item.region_code" :label="item.region_name"
                  :value="item"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item size="medium">
              <el-select clearable v-model="cityTemp" placeholder="请选择市级" @change="handleChange($event,3)"
                value-key="region_name">
                <el-option v-for="item in cityOptions" :key="item.region_code" :label="item.region_name" :value="item">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item size="medium">
              <el-select clearable v-model="areaTemp" placeholder="请选择区/县" @change="handleChange($event,4)"
                value-key="region_name">
                <el-option v-for="item in areaOptions" :key="item.region_code" :label="item.region_name" :value="item">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item size="medium">
              <el-select clearable v-model="townTemp" placeholder="请选择街道/镇" @change="handleChange($event,5)"
                value-key="region_name">
                <el-option v-for="item in townOptions" :key="item.region_code" :label="item.region_name" :value="item">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item size="medium">
              <el-select clearable v-model="villTemp" placeholder="请选择居委会/村" value-key="region_name"
                @change="handleChange($event,6)" >
                <el-option v-for="item in villOptions" :key="item.region_code" :label="item.region_name" :value="item">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4" class="mr10">
            <el-form-item size="medium">
              <el-select v-model="page.config_state" clearable placeholder="全部" @change="onSubmit(page.config_state)">
                <el-option v-for="(item,index) in statusOptions" :key="index" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="2" class="tl mr10">
            <el-form-item size="medium">
              <el-button type="primary" @click="onSubmit" class="general_bgc general_border cfff">查询</el-button>
            </el-form-item>
          </el-col>
        </el-form>
      </el-row>
      <div class="box list">
        <el-table border :data="tableData" style="width: 100%" fit>
          <el-table-column type="index" width="120" label="序号" align="center"></el-table-column>
          <el-table-column prop="config_name" label="系统配置名称" align="center" min-width="120"
            :show-overflow-tooltip="true"></el-table-column>
          <el-table-column label="区域" align="center" min-width="120" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <span>{{scope.row.province}}{{scope.row.city}}{{scope.row.area}}{{scope.row.town}}{{scope.row.vill}}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="状态" min-width="80" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <span class="f12" :class="scope.row.config_state===1?'c67c':'ce6a'">{{scope.row.config_state | statusFilter}}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" fixed="right" min-width="100" :show-overflow-tooltip="true" align="center">
            <template slot-scope="scope">
              <el-button type="text" class="orange" size="medium" @click="toEdit(scope.row)">编辑</el-button>
              <el-button type="text" class="disib" size="medium" :class="{'red':scope.row.config_state==1}" 
                @click="changeState(scope.row)">{{scope.row.config_state | antiStateFilter}}</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="i-page fr mt20">
          <el-pagination v-if="total!=0" background @size-change="handleSizeChange"
            @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10,20,30,50]"
            :page-size="parseInt(page.pager_openset)" layout="total, sizes, prev, pager, next, jumper" :total="total">
          </el-pagination>
        </div>
      </div>
    </el-main>
  </el-container>
</template>
<script>
import axios from "../../../../util/api";
import { local,session } from "../../../../util/util";

export default {
  data () {
    return {
      page: {
        pager_offset: "0",
        pager_openset: "10",
        config_name: "",
        province: "",
        city: "",
        area: "",
        town: "",
        vill: "",
        config_state: ''
      },
      statusOptions: [
        { label: "全部", value: "" },
        { label: "启用", value: "1" },
        { label: "禁用", value: "2" }
      ],
      provinceTemp: "",
      cityTemp: "",
      areaTemp: "",
      townTemp: "",
      villTemp: "",
      provinceFlag: false,
      cityFlag: false,
      areaFlag: false,
      townFlag: false,
      villFlag: false,
      provinceOptions: [],
      cityOptions: [],
      areaOptions: [],
      townOptions: [],
      villOptions: [],
      currentPage: 1,
      total: 0,
      tableData: [],
      userData: {},
      activeName: "1"
    };
  }, filters: {
    statusFilter (status) {
      const statusMap = {
        1: "已启用",
        2: "已禁用"
      };
      return statusMap[status];
    },
    antiStateFilter (state) {
      const stateMap = {
        1: "禁用",
        2: "启用"
      };
      return stateMap[state];
    }
  },
  computed: {
  },
  mounted () {
    this.userData.user_type ='1';
    this.currentPage =
      parseInt(this.page.pager_offset) / parseInt(this.page.pager_openset) + 1;
    this.init(this.page);
    this.handleUserLevel(this.userData);
  },
  methods: {
    init (params) {
      axios.get("/pc/config/list", params).then(v => {
        this.tableData = v.data.config_list;
        this.total = v.data.pager_count;
      });
    },
    async handleUserLevel (userData) {
      if (parseInt(userData.user_type) > 0) {
        this.provinceOptions = await this.fetchRegionData(1);
        let province = this.provinceOptions.find(
          item => item.region_name == userData.user_province
        );
        this.provinceTemp = province;
        if (parseInt(userData.user_type) >= 1 && userData.user_province != null && userData.user_province != '') {
          this.cityOptions = await this.fetchRegionData(2, province.region_code);
          let city = this.cityOptions.find(item => item.region_name == userData.user_city);
          this.provinceFlag = true;
          if (parseInt(userData.user_type) >= 2 && userData.user_city != null) {
            this.areaOptions = await this.fetchRegionData(3, city.region_code);
            let area = this.areaOptions.find(
              item => item.region_name == userData.user_area
            );
            this.cityTemp = city;
            this.cityFlag = true;
            if (parseInt(userData.user_type) >= 3 && userData.user_area != null) {
              this.townOptions = await this.fetchRegionData(4, area.region_code);
              let town = this.townOptions.find(
                item => item.region_name == userData.user_town
              );
              this.areaTemp = area;
              this.areaFlag = true;
              if (parseInt(userData.user_type) >= 4 && userData.user_town != null) {
                this.villOptions = await this.fetchRegionData(5, town.region_code);
                let vill = this.villOptions.find(
                  item => item.region_name == userData.user_vill
                );
                this.townTemp = town;
                this.townFlag = true;
                if (parseInt(userData.user_type) >= 5 && userData.user_vill != null) {
                  this.villTemp = vill;
                  this.villFlag = true;
                }
              }
            }
          }
        }
      }
    },
    fetchRegionData (type, region_high_code) {
      let region_type = type.toString();
      return new Promise((resolve, reject) => {
        axios.get("/pc/region/all", { region_type, region_high_code }).then(response => {
          resolve(response.data.region_list);
        })
          .catch(err => {
            reject(err);
          });
      });
      //  }

    },
    resetPage () {
      this.page = {
        pager_offset: "0",
        pager_openset: "10",
        config_name: "",
        province: "",
        city: "",
        area: "",
        town: "",
        vill: ""
      };
    },
    // 搜索
    onSubmit () {
      this.currentPage = 1;
      this.page.pager_offset = "0";
      this.init(this.page);
    },
    //更改状态
    changeState (row) {
      let { config_id, config_state } = row;
      config_state = config_state == 1 ? 2 : 1;
      axios.put("/pc/config/state/update", { config_id, config_state }).then(response => {
        this.init(this.page);
      });
    },
    handleCurrentChange (val) {
      this.currentPage = val;
      this.page.pager_offset = (val - 1) * this.page.pager_openset + "";
      this.init(this.page);
    },
    handleSizeChange (val) {
      this.page.pager_openset = val + "";
      this.init(this.page);
    },
    async handleChange (item, type) {
      let regionData;
      if (item.region_code == undefined) {
        regionData = ''
      } else {
        regionData = await this.fetchRegionData(type, item.region_code);
      }

      switch (type) {
        case 2:
          this.cityOptions = regionData;
          this.areaOptions = [];
          this.townOptions = [];
          this.villOptions = [];
          this.page.province = item.region_name;
          this.page.city = "";
          this.page.area = "";
          this.page.town = "";
          this.page.vill = "";
          this.cityTemp = "";
          this.areaTemp = "";
          this.townTemp = "";
          this.villTemp = "";
          break;
        case 3:
          this.areaOptions = regionData;
          this.townOptions = [];
          this.villOptions = [];
          this.page.city = item.region_name;
          this.page.area = "";
          this.page.town = "";
          this.page.vill = "";
          this.areaTemp = "";
          this.townTemp = "";
          this.villTemp = "";
          break;
        case 4:
          this.townOptions = regionData;
          this.villOptions = [];
          this.page.area = item.region_name;
          this.page.town = "";
          this.page.vill = "";
          this.townTemp = "";
          this.villTemp = "";
          break;
        case 5:
          this.villOptions = regionData;
          this.page.town = item.region_name;
          this.page.vill = "";
          this.villTemp = "";
          break;
        case 6:
          this.page.vill = item.region_name;
          break;
      }
      this.onSubmit()
    },
    Add(){
      session.set('params',{id:'',name:''})
      this.$router.push({path: 'system/add'})
    },
    toEdit (row) {
      session.set('params',{id:row.config_id,name:''})
      this.$router.push({
        name: "SystemAdd"
      });
    }
  },
};
</script>
<style lang="scss" scoped>
.shopList {
  overflow-x: hidden;
  .add {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  /deep/ .el-input-group__append .el-button--primary .el-icon-search {
    color: #fff;
  }
}
</style>
